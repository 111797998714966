export default [{
    name: 'metadata',
    path: '/metadata',
    meta: {requireAuth: true, roles: ['SA', 'AD']},
    component: () => import('@/views/metadata/mainPage.vue'),
    redirect: "/metadata/data",
    children: [{
        path: '/metadata/data',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/originalData.vue'),
    }, {
        path: '/metadata/testing',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/testingData.vue'),
    }, {
        path: '/metadata/dataparsing',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/dataParsing.vue'),
        props: (route) => ({sn: route.query.sn}),
    }, {
        path: '/metadata/monitorDataParsing',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/monitorDataParsing.vue'),
    }, {
        path: '/metadata/datasend',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/dataSend.vue'),
        redirect: "/metadata/datasend/networking",
        children: [{
            path: '/metadata/datasend/networking',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/operating.vue'),
            props: (route) => ({op: 'networking'}),
        }, {
            path: '/metadata/datasend/setStatus',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/operating.vue'),
            props: (route) => ({op: 'status'}),
        }, {
            path: '/metadata/datasend/information',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/operating.vue'),
            props: (route) => ({op: 'information'}),
        }, {
            path: '/metadata/datasend/upgrade',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/operating.vue'),
            props: (route) => ({op: 'upgrade'}),
        }]
    }, {
        path: '/metadata/mesh',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/meshDataSend.vue'),
        redirect: "/metadata/mesh/networking",
        children: [{
            path: '/metadata/mesh/networking',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'networking', sn: route.query.sn}),
        }, {
            path: '/metadata/mesh/information',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'information'}),
        }, {
            path: '/metadata/mesh/meshSetStatus',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'meshSetStatus'}),
        }, {
            path: '/metadata/mesh/upgrade',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'upgrade'}),
        }, {
            path: '/metadata/mesh/batchUpgrade',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'batchUpgrade'}),
        }, {
            path: '/metadata/mesh/gatewayList',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/meshOperating.vue'),
            props: (route) => ({op: 'gatewayList'}),
        }]
    }, {
        path: '/metadata/old',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/metadata/oldDataSend.vue'),
        redirect: "/metadata/old/networking",
        children: [{
            path: '/metadata/old/networking',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/oldOperating.vue'),
            props: (route) => ({op: 'networking'}),
        }, {
            path: '/metadata/old/readData',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/oldOperating.vue'),
            props: (route) => ({op: 'readData'}),
        }, {
            path: '/metadata/old/information',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/metadata/oldOperating.vue'),
            props: (route) => ({op: 'information'}),
        },]
    }, {
        path: '/metadata/power',
        meta: {requireAuth: true, roles: ['SA', 'AD']},
        component: () => import('@/views/power/main.vue'),
        redirect: '/metadata/power',
        children: [{
            path: "/metadata/power",
            component: () => import('@/views/power/menu.vue'),
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            redirect: '/metadata/power/module',
            children: [{
                path: '/metadata/power/module',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/menu1.vue'),
                props: (route) => ({flag: 'module', sn: route.query.sn}),
            }, {
                path: '/metadata/power/plant',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/menu1.vue'),
                props: (route) => ({flag: 'plant', sn: route.query.sn}),
            }, {
                path: '/metadata/power/statistics',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/statistics.vue'),
            }]
        }, {
            path: '/metadata/power/plantLocation',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/map/plantLocation.vue'),
            props: (route) => ({plantNumber: route.query.plantNumber}),
        }, {
            path: '/metadata/power/plantNewLocation',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/map/plantNewLocation.vue'),
        }, {
            path: '/metadata/power/layout',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/layout/main.vue'),
            props: (route) => ({plantNumber: route.query.plantNumber}),
            redirect: '/metadata/power/layout/layout2D',
            children: [{
                path: '/metadata/power/layout/layout2D',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/layout2D.vue'),
            }, {
                path: '/metadata/power/layout/layout3D',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/layout3D.vue'),
            }, {
                path: '/metadata/power/layout/layoutSet',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/layoutSet.vue'),
            }, {
                path: '/metadata/power/layout/layoutStatic',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/layoutStatic.vue'),
            }, {
                path: '/metadata/power/layout/layoutString',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/layoutString.vue'),
            }, {
                path: '/metadata/power/layout/all',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/layout/all.vue'),
            },]
        }, {
            path: '/metadata/plant',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/plant/plant.vue'),
            redirect: '/metadata/plant/plantList',
            children: [{
                path: '/metadata/plant/plantList',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/plantList4admin.vue'),
                props: (route) => ({plantNumber: route.query.plantNumber}),
            }, {
                path: '/metadata/plant/image',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/images.vue'),
                props: (route) => ({flag: 'plant', id: route.query.id}),
            }, {
                path: '/metadata/plant/moduleList',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/moduleListInPlant.vue'),
                props: (route) => ({plantNumber: route.query.plantNumber}),
            },]
        }, {
            path: '/metadata/plant/status',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/plant/plantStatus/plantStatus.vue'),
            redirect: '/metadata/plant/status/blog',
            children: [{
                path: '/metadata/plant/status/state',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/plantStatus/plantWorkingState.vue'),
                props: (route) => ({plantNumber: route.query.plantNumber}),
            }, {
                path: '/metadata/plant/status/blog',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/plantStatus/plantBlog.vue'),
                props: (route) => ({plantNumber: route.query.plantNumber}),
            },]
        }, {
            path: '/metadata/module',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/module/module.vue'),
            redirect: '/metadata/module/list',
            children: [{
                path: '/metadata/module/list',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleList.vue'),
                props: (route) => ({userName: route.query.userName}),
            }, {
                path: '/metadata/module/moduleSharing',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/sharingList.vue'),
                props: (route) => ({userName: route.query.userName}),
            }, {
                path: '/metadata/module/monitorChanged',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/monitorsChangedList.vue'),
                props: (route) => ({moduleId: route.query.moduleId}),
            }]
        }, {
            path: '/metadata/moduleType',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/module/moduleType/moduleType.vue'),
            redirect: '/metadata/moduleType/list',
            children: [{
                path: '/metadata/moduleType/list',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleType/moduleTypeList.vue'),
                props: (route) => ({moduleType: route.query.moduleType}),
            }, {
                path: '/metadata/moduleType/moduleSpecification',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleType/moduleSpecification.vue'),
                props: (route) => ({moduleType: route.query.moduleType}),
            }, {
                path: '/metadata/moduleType/monitorSpecification',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleType/monitorSpecification.vue'),
                props: (route) => ({monitorType: route.query.monitorType}),
            }, {
                path: '/metadata/moduleType/powerGrid',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleType/powerGrid.vue'),
            }, {
                path: '/metadata/moduleType/image',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/plant/images.vue'),
                props: (route) => ({flag: 'moduleType', id: route.query.id}),
            },]
        }, {
            path: '/metadata/module/status',
            meta: {requireAuth: true, roles: ['SA', 'AD']},
            component: () => import('@/views/power/module/moduleStatus/moduleStatus.vue'),
            redirect: '/metadata/module/status/blog',
            children: [{
                path: '/metadata/module/status/state',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleStatus/moduleWorkingState.vue'),
                props: (route) => ({moduleId: route.query.moduleId}),
            }, {
                path: '/metadata/module/status/blog',
                meta: {requireAuth: true, roles: ['SA', 'AD']},
                component: () => import('@/views/power/module/moduleStatus/moduleBlog.vue'),
                props: (route) => ({moduleId: route.query.moduleId}),
            }]
        }]
    }]
}];
