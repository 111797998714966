export default [{
    name: 'testing',
    path: '/testing',
    meta: {requireAuth: false},
    component: () => import('@/views/testing/mainPage.vue'),
    redirect: "/testing/prod",
    children: [
        {path: '/testing/prod', component: () => import('@/views/testing/prod.vue')},
        {path: '/testing/body', component: () => import('@/views/testing/dev.vue')},
        {path: '/testing/dev', component: () => import('@/views/testing/dev.vue')}
    ]
}];
